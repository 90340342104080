import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@components/layout';
import SEO from '@components/seo';

const Faq = ({ data }) => (
  <Layout baseRoute="faq">
    <SEO title="FAQ" />
    <h1>FAQ</h1>
    <div className="questions-wrapper" dangerouslySetInnerHTML={{ __html: data.wpPage.content }} />
  </Layout>
);
export const pageQuery = graphql`
  query {
    wpPage(slug: { eq: "faq" }) {
      id
      content
    }
  }
`;
export default Faq;
